module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"Tipsta","accessToken":"MC5YM25wcFJFQUFDTUFaTnNK.Ae-_ve-_vVZzMBp877-977-977-9Mu-_ve-_ve-_ve-_ve-_ve-_ve-_vRlTTxXvv73vv70Ecu-_vVwt77-9Jw","schemas":{"legaldoc":{"Main":{"uid":{"type":"UID","config":{"label":"slug"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"TItle"}},"introduction":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","allowTargetBlank":true,"label":"Introduction"}},"last_modified":{"type":"Date","config":{"label":"Last Modified"}},"version":{"type":"Text","config":{"label":"Version"}},"content":{"type":"Group","config":{"fields":{"slug":{"type":"Text","config":{"label":"slug"}},"section_title":{"type":"StructuredText","config":{"single":"heading2","label":"Section Title"}},"section_content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","allowTargetBlank":true,"label":"Section Content"}}},"label":"Content"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tipsta","short_name":"Tipsta","start_url":"/","background_color":"#7a0bd5","theme_color":"#7a0bd5","display":"minimal-ui","icon":"src/images/logo512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"14c55a33554be78cee734fb2d7975e28"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/theme/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
