import { useCallback, useState, useEffect, useRef } from "react"
import firebase from "gatsby-plugin-firebase"

import { AuthService, User } from "../services/Auth.service"
import { delay } from "../../../lib/utils"
import { setAuthToken as setApiAuthToken } from "../../../lib/api/links"
import { AnalyticsService, UserProperty } from "../../../lib/analytics"
import { useRefreshAuthToken } from "./useRefreshAuthToken"

export interface UseAuth {
  initialising: boolean
  authToken: string,
  userId: string,
  emailAddress: string,
  isAnonymous: boolean,
  isEmailVerified: boolean,
  onLogout: () => Promise<void>
  onRefreshUserData: () => Promise<void>
}

export const defaultState: UseAuth = {
  initialising: false,
  authToken: '',
  userId: '',
  emailAddress: '',
  isAnonymous: true,
  isEmailVerified: false,
  onLogout: async () => {
    console.log("onLogout")
  },
  onRefreshUserData: async () => {
    console.log("onRefreshUserData")
  }
}

export const useAuth = (): UseAuth => {
  const [gettingInitialAuth, setGettingInitialAuth] = useState(true)
  const [initialising, setInitialising] = useState(true)
  const [authToken, setAuthToken] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isAnonymous, setIsAnonymous] = useState(true)
  const [userId, setUserId] = useState('')

  useRefreshAuthToken()

  const getAuthToken = useCallback(async (user: User) => {
    const token = await user.getIdToken()
    setAuthToken(token)
    // use a timeout to allow for other hooks to run before initialising is turned off
    await delay(0)
  }, [setAuthToken])

  const onRefreshUserData = useCallback(async () => {
    const currUser = firebase.auth().currentUser
    if (currUser) {
      await onUserChanged(currUser)
    }
  }, [])

  const onLogout = useCallback(async () => {
    await AuthService.logout()
    onRefreshUserData()
  }, [])

  const onUserChanged = useCallback(async (user: firebase.User | null): Promise<void> => {
    if (user) {
      await getAuthToken(user)
      const { uid, email, isAnonymous, emailVerified } = user;
      setEmailAddress(email || '')
      setIsEmailVerified(emailVerified)
      setIsAnonymous(isAnonymous)
      setUserId(uid)
      AnalyticsService.setUserId(uid)
      AnalyticsService.setUserProperty(UserProperty.anonymous, isAnonymous)
      setInitialising(false)
    } else {
      AnalyticsService.setUserId('')
    }
    setGettingInitialAuth(false)
  }, [setGettingInitialAuth, setIsAnonymous, setEmailAddress, setIsEmailVerified, setUserId, getAuthToken, setInitialising])

  useEffect(() => {
    const unsubscribeIdToken = firebase.auth().onIdTokenChanged(onUserChanged)
    const unsubscribUserState = firebase
      .auth()
      .onAuthStateChanged(onUserChanged)
    return (): void => {
      unsubscribeIdToken()
      unsubscribUserState()
    }
  }, [onUserChanged])

  useEffect(() => {
    if (!gettingInitialAuth && !authToken) {
      if (!firebase.auth().currentUser) {
        AuthService.signInAnonymously()
      }
    }
  }, [gettingInitialAuth, authToken])

  useEffect(() => {
    setApiAuthToken(authToken)
  }, [authToken])

  const initialisingRef = useRef(initialising)
  initialisingRef.current = initialising
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // if still initialising after 15s - reset 
      if (initialisingRef.current) {
        onLogout()
      }
    }, 15000)
    return () => clearTimeout(timeoutId)
  }, [initialisingRef, onLogout])

  return {
    initialising,
    authToken,
    userId,
    emailAddress,
    isEmailVerified,
    isAnonymous,
    onLogout,
    onRefreshUserData
  }
}